.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),
    url('../assets/hero-banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: overlay;
  align-self: stretch;
  height: 20em;
  @include respond('md-') {
    height: 40em;
  }
  &__caption {
    text-align: center;
    font-size: 6vw;
    text-transform: uppercase;
    margin-right: 0.4em;
  }
  &__skill {
    text-align: center;
    font-size: 6vw;
    text-transform: uppercase;
  }
}

.contact-form {
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),
    url('../assets/contact-banner.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: overlay;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  & .contact__caption {
    text-transform: capitalize;
    font-size: 4vmax;
  }
  form {
    width: 60%;
    @include respond('md-') {
      width: 30%;
    }
  }
  padding: 1rem;
}
