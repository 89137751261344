@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Dosis&display=swap');

html {
  background-color: #141618;
  color: white;
}

body {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 25px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 1rem;
  @include respond('md-') {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .nav-items {
    display: flex;
    flex-flow: row nowrap;
    li {
      margin-right: 2rem;
    }
  }
}

.footer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: auto;
  padding: 2rem 0;
  .nav-items {
    display: flex;
    flex-flow: row nowrap;
    li {
      margin-right: 2rem;
    }
  }
}
