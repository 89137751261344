.project {
  padding: 2rem 0;
}

.github__projects,
.tooling__projects {
  & .single {
    border-bottom: 1px solid #aaaaaa;
    &:last-child {
      border-bottom: none;
    }
  }
  & .project {
    &__title {
      text-transform: uppercase;
      :hover {
        color: #dddddd;
      }
    }
    &__description {
      color: #dddddd;
      font-family: 'Dosis', sans-serif;
    }
  }
}

.app__projects {
  display: flex;
  flex-flow: column nowrap;
  & .single {
    display: flex;
    flex-flow: column nowrap;
    @include respond('md-') {
      flex-flow: row nowrap;
    }
    align-items: center;
    position: relative;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0rem;
    }

    .project__image {
      @include respond('as') {
        order: 0 !important;
      }
    }
    & .project__description {
      color: #ffffff;
      text-align: center;
      @include respond('as') {
        order: 1 !important;
      }
      h3 {
        & a:hover {
          color: #cccccc;
        }
        @include respond('md-') {
          font-size: 2em;
          line-height: 1em;
        }
      }
      p {
        color: #ffffff;
        font-family: 'Dosis', sans-serif;
        @include respond('md-') {
          font-size: 1.5em;
          line-height: 1.2em;
        }
      }
    }
  }
}
