.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  &-3 {
    width: 100%;
    @include respond('md-') {
      width: 30%;
    }
  }
  &-9 {
    width: 100%;
    @include respond('md-') {
      width: 70%;
    }
  }
}

.half-col {
  display: flex;
  flex-flow: column nowrap;
  flex-basis: 50%;
}

.col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
