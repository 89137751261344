.form-group {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1em;
  input {
    align-self: stretch;
  }
}

input {
  background: rgba(20, 22, 24, 0.5);
  border-color: white;
  height: 2rem;
  color: white;
  font-size: 18px;
  border-style: solid;
  border-width: 1px;
}

textarea {
  background: rgba(20, 22, 24, 0.5);
  border-color: white;
  color: white;
  font-size: 18px;
  border-style: solid;
  border-width: 1px;
  height: 6em;
}

.btn {
  width: 8em;
  height: 3rem;
  padding: 0.5rem;
  background-color: rgba(20, 22, 24, 0.7);
  color: white;
  align-self: center;
  font-size: 18px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
    color: #141618;
    border-color: rgba(20, 22, 24, 0.7);
  }
  transition: 0.5s;
  transition-timing-function: ease-in;
}
