.about__content {
  font-family: 'Dosis', sans-serif;
  padding: 1em;
  font-size: 1.2em;
  line-height: 1.2em;
  @include respond('md-') {
    font-size: 1.3em;
    line-height: 1.3em;
  }
}

.about__hero {
  display: flex;
  justify-content: center;
  img {
    @include respond('md-') {
      object-fit: contain;
      height: 100vh;
    }
  }
}
