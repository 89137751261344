/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  border: 1px solid rgb(225, 225, 225);
  border-radius: 2px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgb(225, 225, 225);
}

.accordion__button {
  background-color: #141618;
  color: rgb(225, 225, 225);
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  border: none;
  padding: 4%;
  width: 92%;
  transition: 0.3s;
  transition-timing-function: ease-in;
  @include respond('md-') {
    padding: 2%;
    width: 96%;
    font-size: 25px;
  }

  &:hover {
    background-color: #eeeeee;
    color: #141618;
  }

  &:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
  }
  &[aria-expanded='true']:before,
  &[aria-selected='true']:before {
    transform: rotate(45deg);
  }
}

.accordion__panel {
  padding: 4%;
  width: 92%;
  animation: fadein 0.35s ease-in;
  @include respond('md-') {
    padding: 2%;
    width: 96%;
  }
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
