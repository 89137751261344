@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Dosis&display=swap);
html {
  background-color: #141618;
  color: white; }

body {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 25px; }

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.header {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 1rem; }
  @media (min-width: 768px) {
    .header {
      flex-flow: row nowrap;
      justify-content: space-between; } }
  .header .nav-items {
    display: flex;
    flex-flow: row nowrap; }
    .header .nav-items li {
      margin-right: 2rem; }

.footer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: auto;
  padding: 2rem 0; }
  .footer .nav-items {
    display: flex;
    flex-flow: row nowrap; }
    .footer .nav-items li {
      margin-right: 2rem; }

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%; }
  .row-3 {
    width: 100%; }
    @media (min-width: 768px) {
      .row-3 {
        width: 30%; } }
  .row-9 {
    width: 100%; }
    @media (min-width: 768px) {
      .row-9 {
        width: 70%; } }

.half-col {
  display: flex;
  flex-flow: column nowrap;
  flex-basis: 50%; }

.col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }

li {
  list-style: none; }

a {
  text-decoration: none;
  color: white; }

.form-group {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1em; }
  .form-group input {
    align-self: stretch; }

input {
  background: rgba(20, 22, 24, 0.5);
  border-color: white;
  height: 2rem;
  color: white;
  font-size: 18px;
  border-style: solid;
  border-width: 1px; }

textarea {
  background: rgba(20, 22, 24, 0.5);
  border-color: white;
  color: white;
  font-size: 18px;
  border-style: solid;
  border-width: 1px;
  height: 6em; }

.btn {
  width: 8em;
  height: 3rem;
  padding: 0.5rem;
  background-color: rgba(20, 22, 24, 0.7);
  color: white;
  align-self: center;
  font-size: 18px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in; }
  .btn:hover {
    background-color: rgba(255, 255, 255, 0.7);
    color: #141618;
    border-color: rgba(20, 22, 24, 0.7); }

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  border: 1px solid #e1e1e1;
  border-radius: 2px; }

.accordion__item + .accordion__item {
  border-top: 1px solid #e1e1e1; }

.accordion__button {
  background-color: #141618;
  color: #e1e1e1;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  border: none;
  padding: 4%;
  width: 92%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in; }
  @media (min-width: 768px) {
    .accordion__button {
      padding: 2%;
      width: 96%;
      font-size: 25px; } }
  .accordion__button:hover {
    background-color: #eeeeee;
    color: #141618; }
  .accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .accordion__button[aria-expanded='true']:before, .accordion__button[aria-selected='true']:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }

.accordion__panel {
  padding: 4%;
  width: 92%;
  -webkit-animation: fadein 0.35s ease-in;
          animation: fadein 0.35s ease-in; }
  @media (min-width: 768px) {
    .accordion__panel {
      padding: 2%;
      width: 96%; } }

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */
@-webkit-keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(black)), url(/static/media/hero-banner.f96dd5e4.png);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), black), url(/static/media/hero-banner.f96dd5e4.png);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), black), url(/static/media/hero-banner.f96dd5e4.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: overlay;
  align-self: stretch;
  height: 20em; }
  @media (min-width: 768px) {
    .hero {
      height: 40em; } }
  .hero__caption {
    text-align: center;
    font-size: 6vw;
    text-transform: uppercase;
    margin-right: 0.4em; }
  .hero__skill {
    text-align: center;
    font-size: 6vw;
    text-transform: uppercase; }

.contact-form {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0)), to(black)), url(/static/media/contact-banner.547bd245.jpg);
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0), black), url(/static/media/contact-banner.547bd245.jpg);
  background: linear-gradient(to top, rgba(0, 0, 0, 0), black), url(/static/media/contact-banner.547bd245.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: overlay;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 1rem; }
  .contact-form .contact__caption {
    text-transform: capitalize;
    font-size: 4vmax; }
  .contact-form form {
    width: 60%; }
    @media (min-width: 768px) {
      .contact-form form {
        width: 30%; } }

.project {
  padding: 2rem 0; }

.github__projects .single,
.tooling__projects .single {
  border-bottom: 1px solid #aaaaaa; }
  .github__projects .single:last-child,
  .tooling__projects .single:last-child {
    border-bottom: none; }

.github__projects .project__title,
.tooling__projects .project__title {
  text-transform: uppercase; }
  .github__projects .project__title :hover,
  .tooling__projects .project__title :hover {
    color: #dddddd; }

.github__projects .project__description,
.tooling__projects .project__description {
  color: #dddddd;
  font-family: 'Dosis', sans-serif; }

.app__projects {
  display: flex;
  flex-flow: column nowrap; }
  .app__projects .single {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    position: relative;
    margin-bottom: 1rem; }
    @media (min-width: 768px) {
      .app__projects .single {
        flex-flow: row nowrap; } }
    .app__projects .single:last-child {
      margin-bottom: 0rem; }
    @media (max-width: 768px) {
      .app__projects .single .project__image {
        order: 0 !important; } }
    .app__projects .single .project__description {
      color: #ffffff;
      text-align: center; }
      @media (max-width: 768px) {
        .app__projects .single .project__description {
          order: 1 !important; } }
      .app__projects .single .project__description h3 a:hover {
        color: #cccccc; }
      @media (min-width: 768px) {
        .app__projects .single .project__description h3 {
          font-size: 2em;
          line-height: 1em; } }
      .app__projects .single .project__description p {
        color: #ffffff;
        font-family: 'Dosis', sans-serif; }
        @media (min-width: 768px) {
          .app__projects .single .project__description p {
            font-size: 1.5em;
            line-height: 1.2em; } }

.articles {
  align-items: center;
  justify-content: center; }

.articles__heading {
  text-align: center;
  text-transform: capitalize;
  font-size: 1.2em;
  padding: 0 1em;
  line-height: 1.3em; }
  @media (min-width: 768px) {
    .articles__heading {
      font-size: 1.5em;
      padding: 0 0; } }

.article__list {
  text-align: center;
  text-transform: uppercase; }
  .article__list li {
    list-style: disc;
    margin-top: 1em; }
    .article__list li a {
      color: #cccccc;
      font-family: 'Dosis', sans-serif;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      -webkit-transition-timing-function: ease-in;
              transition-timing-function: ease-in; }
      .article__list li a:hover {
        color: #ffffff; }

.about__content {
  font-family: 'Dosis', sans-serif;
  padding: 1em;
  font-size: 1.2em;
  line-height: 1.2em; }
  @media (min-width: 768px) {
    .about__content {
      font-size: 1.3em;
      line-height: 1.3em; } }

.about__hero {
  display: flex;
  justify-content: center; }
  @media (min-width: 768px) {
    .about__hero img {
      object-fit: contain;
      height: 100vh; } }

