.articles {
  align-items: center;
  justify-content: center;
}

.articles__heading {
  text-align: center;
  text-transform: capitalize;
  font-size: 1.2em;
  padding: 0 1em;
  line-height: 1.3em;

  @include respond('md-') {
    font-size: 1.5em;
    padding: 0 0;
  }
}

.article__list {
  text-align: center;
  text-transform: uppercase;
  li {
    list-style: disc;
    margin-top: 1em;
    a {
      color: #cccccc;
      font-family: 'Dosis', sans-serif;
      &:hover {
        color: #ffffff;
      }
      transition: 0.2s;
      transition-timing-function: ease-in;
    }
  }
}
